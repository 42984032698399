import React from 'react';
import {InlineWidget} from 'react-calendly'
const Banner = () => {
  return (

    <div className="home-page page3" >
        <div className="calendlyWrapper" style={{ maxWidth: '100%' }}>
        <h1 key="h1">THANKS!</h1>
        <p key="content">
          Please select a time slot for your free consultation below.
        </p>
        <InlineWidget
        pageSettings={{
            backgroundColor: 'ffffff',
            hideEventTypeDetails: false,
            hideLandingPageDetails: false,
            primaryColor: '00a2ff',
            textColor: '4d5055'
        }}
    
        url="https://calendly.com/sortdio"
        styles={{
          height: '700px'
        }}
        />
        </div>
    </div>
  );
}



export default Banner;
