import React from 'react';
import { injectIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import Banner from './Banner';


function Home(props) {
  const { intl } = props;
  return (
    <>
      <Helmet>
        <title>{`Sortd - ${intl.formatMessage({
          id: 'app.home.slogan',
        })}`}</title>
        <meta
          name="description"
          content={`Sortd - ${intl.formatMessage({
            id: 'app.home.slogan',
          })}`}
        />
      </Helmet>
      <div className="home-wrapper">
        <Banner />
      </div>
    </>
  );
}

export default injectIntl(Home);
